<template>
  <q-btn
    color="black"
    fab
    icon="my_location"
    @click="getLocation"
  />
</template>

<script>
import { mapActions } from 'vuex'
import { useQuasar } from 'quasar'

export default {
  name: 'SpotLocation',
  components: {},

  props: {
    spot_id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      error: '',
    }
  },

  watch: {
    error(newValue) {
      if (newValue != '') {
        this.q.loading.hide()
        this.showAlert(newValue, 'error')
        this.error = ''
      }
    },
  },

  created() {
    this.q = useQuasar()
  },
  beforeUnmount() {
    this.q.loading.hide()
  },

  methods: {
    ...mapActions({
      validateLocation: 'rally/validateLocation',
    }),
    getLocation() {
      this.q.loading.show()

      if (!navigator.geolocation) {
        this.error = 'このブラウザはサポートされていません'
        return
      }

      navigator.geolocation.getCurrentPosition(
        this.successFunc,
        this.errorFunc,
        {
          enableHighAccuracy: false,
          timeout: 5000,
          maximumAge: 0,
        }
      )
    },
    successFunc(position) {
      const latitude = position.coords.latitude
      const longitude = position.coords.longitude
      this.dataValidation(latitude, longitude)
    },
    errorFunc(error) {
      switch (error.code) {
        case 1: //PERMISSION_DENIED
          this.error = '位置情報の利用を許可してください'
          break
        case 2: //POSITION_UNAVAILABLE
          this.error = '現在位置が取得できませんでした'
          break
        case 3: //TIMEOUT
          this.error = 'タイムアウトになりました'
          break
        default:
          this.error = '現在位置が取得できませんでした'
          break
      }
    },
    dataValidation(latitude, longitude) {
      // 位置情報送信
      const data = {
        id: this.spot_id,
        latitude: latitude,
        longitude: longitude,
      }
      this.q.loading.show()
      this.validateLocation(data)
        .catch(() => {
          this.showAlert('この場所にクイズはありません。')
        })
        .finally(() => {
          this.q.loading.hide()
        })
    },
    showAlert(message, alert_type = 'warning') {
      this.$swal.fire({
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        backdrop: 'rgba(255,255,255,0.9)',
        background: 'transparent',
        icon: alert_type,
        showConfirmButton: false,
        text: message,
        timer: 2000,
      })
    },
  },
}
</script>
