import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  arrayUnion,
  Timestamp,
} from 'firebase/firestore'
import dayjs from 'dayjs'

// State（状態）
const state = () => ({
  log: {},
})

// Getters（算出プロパティ）
const getters = {
  gameStatus(state, getters) {
    let status = {}
    if (!getters.isComplete && !getters.isFinished) {
      status = {
        slug: 'entry',
        message: '参加中',
      }
    }
    if (!getters.isComplete && getters.isFinished) {
      status = {
        slug: 'timeOver',
        message: 'タイムオーバー',
      }
    }
    if (getters.isComplete && getters.isFinished) {
      status = {
        slug: 'gameClear',
        message: 'ゲームクリア',
      }
    }
    return status
  },
  isEntry(state) {
    return !!Object.keys(state.log).length
  },
  isFinished(state, getters) {
    if (getters.isEntry) {
      return state.log.end_datetime !== null
    }
  },
  isComplete(state, getters, rootState) {
    const spots = rootState.rally.data.spots.map((spot) => {
      return spot.id
    })
    return spots.filter((i) => getters.clearSpots.indexOf(i) == -1).length === 0
  },
  timeLimit(state, getters) {
    if (getters.isEntry) {
      return Number(state.log.time_limit.seconds)
    }
  },
  nickName: (state, getters) => {
    if (getters.isEntry) {
      return state.log.nickname
    }
    return null
  },
  logDocName: (state, getters, rootState) => (uid) => {
    if (rootState.rally.slug && uid) {
      return String(rootState.rally.slug + '_' + uid)
    }
    return null
  },
  logDocRef: (state, getters) => (uid) => {
    const db = getFirestore()
    const log_name = getters.logDocName(uid)
    return doc(db, 'game_logs', log_name)
  },
  clearSpots: (state, getters) => {
    if (getters.isEntry) {
      return state.log.spots
        .filter((spot) => {
          // 繰り返し可：スポットIDと解答日時
          if (Number.isFinite(spot.id) && spot.is_correct === true) {
            return true
          }
        })
        .map((spot) => {
          return spot.id
        })
    }
  },
}

// Mutations（状態変更）
const mutations = {
  set(state, payload) {
    state.log = payload.log
  },
  clear(state) {
    state.log = {}
  },
}

// Actions（処理）
const actions = {
  /**
   * ゲーム参加
   * @return {Promise}
   */
  entry({ commit, dispatch, getters, rootGetters }, nickname) {
    return new Promise((resolve) => {
      dispatch('account/signIn', null, { root: true }).then((user) => {
        const now = dayjs()
        const rally = rootGetters['rally/currentRally']
        const docData = {
          uid: user.uid,
          nickname: nickname,
          rally_id: rally.id,
          rally_slug: rally.slug,
          entry_number: now.valueOf(),
          begin_datetime: Timestamp.fromMillis(now.valueOf()),
          end_datetime: null,
          time_limit: Timestamp.fromMillis(
            now.add(rally.time_limit, 'second').valueOf()
          ),
          spots: [],
        }
        const docRef = getters.logDocRef(user.uid)
        setDoc(docRef, docData).then(() => {
          commit('set', { log: docData })
          resolve()
        })
      })
    })
  },
  /**
   * 参加情報破棄
   * @return {Promise}
   */
  retry({ commit, dispatch }) {
    return new Promise((resolve) => {
      dispatch('account/signOut', null, { root: true })
      commit('clear')
      resolve()
    })
  },
  /**
   * 参加情報再取得
   * @return {void}
   */
  restore({ dispatch }) {
    return new Promise((resolve) => {
      dispatch('account/restore', null, { root: true })
        .then((user) => {
          dispatch('$_gameLog_restore', user.uid)
          resolve()
        })
        .catch(() => {})
    })
  },
  $_gameLog_restore({ commit, getters }, uid) {
    const docRef = getters.logDocRef(uid)
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const docData = docSnap.data()
        commit('set', { log: docData })
      }
    })
  },
  addSpot({ state, commit, getters }, spot) {
    spot['answer_datetime'] = Timestamp.now()

    const docRef = getters.logDocRef(state.log.uid)
    updateDoc(docRef, { spots: arrayUnion(spot) }).then(() => {
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          commit('set', { log: docSnap.data() })
        }
      })
    })
  },
  finish({ state, commit, getters }) {
    return new Promise((resolve) => {
      if (state.log.end_datetime == null) {
        const docRef = getters.logDocRef(state.log.uid)
        const end_datetime = Timestamp.now()
        updateDoc(docRef, { end_datetime: end_datetime }).then(() => {
          getDoc(docRef).then((docSnap) => {
            if (docSnap.exists()) {
              commit('set', { log: docSnap.data() })
              resolve()
            }
          })
        })
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
