import { createRouter, createWebHashHistory } from 'vue-router'

// pages
import NotFound from '@/pages/NotFound.vue'
import Rally from '@/pages/Rally.vue'
import RallyHome from '@/pages/RallyHome.vue'
import RallySheet from '@/pages/RallySheet.vue'
import RallyMap from '@/pages/RallyMap.vue'
import RallyRanking from '@/pages/RallyRanking.vue'
import RallySpots from '@/pages/RallySpots.vue'
import RallySpot from '@/pages/RallySpot.vue'

// store
import store from '@/store/index.js'

const routes = [
  {
    path: '/e/:slug([a-zA-Z0-9]+?)',
    component: Rally,
    name: 'Rally',
    meta: { requiresSlug: true },
    children: [
      {
        path: '',
        component: RallyHome,
        name: 'RallyHome',
      },
      {
        path: 'sheet',
        component: RallySheet,
        name: 'RallySheet',
        meta: { requiresEntry: true },
        children: [
          {
            path: '',
            component: RallySpots,
            name: 'RallySpots',
          },
          {
            path: 's/:id([0-9]+?)',
            component: RallySpot,
            name: 'RallySpot',
            props: (route) => ({id: Number(route.params.id)}),
          },
        ],
      },
      {
        path: 'map',
        component: RallyMap,
        name: 'RallyMap',
        meta: { requiresEntry: true },
      },
      {
        path: 'ranking',
        component: RallyRanking,
        name: 'RallyRanking',
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    name: 'NotFound',
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresSlug && to.params.slug !== store.getters['rally/slug']) {
    store.commit('rally/setSlug', to.params.slug)
  }

  if (to.meta.requiresEntry && !store.getters['gameLog/isEntry']) {
    router.replace({
      name: 'RallyHome',
      params: { slug: to.params.slug },
      query: { redirect: to.fullPath },
    })
  } else {
    next()
  }
})

export default router
