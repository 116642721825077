<template>
  <q-dialog
    ref="dialog"
    maximized
    persistent
    transition-show="rotate"
    transition-hide="scale"
    @hide="dialogClose"
  >
    <q-card class="bg-white">
      <q-card-section class="row items-center q-pb-none">
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>
      <q-card-section>
        <q-img v-if="question.image" :src="question.image" fit="cover">
          <template v-slot:error>
            <div class="absolute-full flex flex-center bg-gray text-white">
              <span class="text-h4">No Image</span>
            </div>
          </template>
        </q-img>
        <p>{{ question.question }}</p>
        <p>{{ question.hint }}</p>
        <q-btn
          v-for="answer in question.answers"
          :key="answer.id"
          color="primary"
          class="full-width"
          :label="answer.answer"
          size="lg"
          @click="challengeQuestion(answer.answer)"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SpotQuestion',
  components: {},

  computed: {
    ...mapGetters({
      question: 'rally/question',
      isQuestion: 'rally/isQuestion',
      isComplete: 'gameLog/isComplete',
    }),
  },

  watch: {
    isQuestion(newQuestion) {
      if (newQuestion) {
        this.$refs.dialog.show()
      } else {
        this.$refs.dialog.hide()
      }
    },
  },

  mounted() {
    this.$refs.dialog.value = false
  },

  methods: {
    ...mapActions({
      challenge: 'rally/challenge',
      cancelQuestion: 'rally/cancelQuestion',
      addSpot: 'gameLog/addSpot',
      finish: 'gameLog/finish',
    }),
    challengeQuestion(answer) {
      const data = {
        id: this.question.id,
        spot_id: this.question.spot_id,
        answer: answer,
      }

      let is_correct
      this.challenge(data)
        .then(() => {
          // 正解時の処理
          is_correct = true
        })
        .catch(() => {
          // 不正解時の処理
          is_correct = false
        })
        .finally(() => {
          const spot = {
            id: data.spot_id,
            is_correct: is_correct,
          }
          this.addSpot(spot)
          this.showAlert(is_correct)
        })
    },
    showAlert(correct) {
      let icon, title
      if (correct) {
        icon = 'success'
        title = '正解！'
      } else {
        icon = 'error'
        title = '不正解…'
      }
      this.$swal.fire({
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        backdrop: 'rgba(255,255,255,0.9)',
        background: 'transparent',
        didClose: () => {
          if (this.isComplete) {
            this.finish().then(() => {
              this.showFinish()
            })
          }
        },
        icon: icon,
        // imageUrl:imageUrl,
        showConfirmButton: false,
        timer: 2000,
        title: title,
      })
    },
    showFinish() {
      this.$swal.fire({
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        backdrop: 'rgba(255,255,255,0.7)',
        background: 'transparent',
        imageUrl:
          'https://storage.googleapis.com/escaper-205423.appspot.com/images/finish.png',
        showConfirmButton: false,
        timer: 2000,
      })
    },
    dialogClose() {
      this.cancelQuestion()
    },
  },
}
</script>
