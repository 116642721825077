import router from '@/router'
import axios from 'axios'

const api = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
})

// 許容する範囲（m）
const tolerance = 5

// 緯度経度から2地点間の距離 (m) を計算する
// 地球を（回転楕円体でなく）半径 6,371km の球体とした場合
const getDistance = function(lat1, lng1, lat2, lng2) {
  const R = Math.PI / 180
  lat1 *= R
  lng1 *= R
  lat2 *= R
  lng2 *= R
  return (
    6371 *
    1000 *
    Math.acos(
      Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) +
        Math.sin(lat1) * Math.sin(lat2)
    )
  )
}

// State（状態）
const state = () => ({
  slug: null,
  data: {},
  ranking: [],
  spots: [],
  spot: {},
  question: {},
})

// Getters（算出プロパティ）
const getters = {
  slug: (state) => state.slug,
  currentRally: (state) => state.data,
  ranking: (state) => state.ranking,
  spots: (state) => state.spots,
  spot: (state) => state.spot,
  question: (state) => state.question,
  isQuestion(state) {
    return !!Object.keys(state.question).length
  },
  showMap: (state) => state.data.show_map,
  showRanking: (state) => state.data.show_ranking,
}

// Mutations（状態変更）
const mutations = {
  setSlug: (state, slug) => (state.slug = slug),
  setRally: (state, rally) => (state.data = rally),
  clearRally: (state) => (state.data = {}),
  setRanking: (state, ranking) => (state.ranking = ranking),
  clearRanking: (state) => (state.ranking = {}),
  setSpots: (state, spots) => (state.spots = spots),
  clearSpots: (state) => (state.spots = {}),
  setSpot: (state, spot) => (state.spot = spot),
  clearSpot: (state) => (state.spot = {}),
  setQuestion: (state, question) => (state.question = question),
  clearQuestion: (state) => (state.question = {}),
}

// Actions（処理）
const actions = {
  fetchRally({ state, commit }) {
    return new Promise((resolve, reject) => {
      api
        .get('rally/' + state.slug)
        .then((response) => {
          commit('setRally', response.data)
          resolve()
        })
        .catch(() => {
          commit('clearRally')
          reject()
        })
    })
  },
  getRanking({ state, commit }) {
    api
      .get('rally/' + state.slug + '/ranking')
      .then((response) => {
        commit('setRanking', response.data)
      })
      .catch(() => {
        commit('clearRanking')
        router.push({ name: 'NotFound' })
      })
  },
  getSpots({ state, commit }) {
    api
      .get('rally/' + state.slug + '/spots')
      .then((response) => {
        commit('setSpots', response.data)
      })
      .catch(() => {
        commit('clearSpots')
        router.push({ name: 'NotFound' })
      })
  },
  getSpot({ state, commit }, spot_id) {
    api
      .get('rally/' + state.slug + '/spot/' + spot_id)
      .then((response) => {
        commit('setSpot', response.data)
      })
      .catch(() => {
        commit('clearSpot')
        router.push({ name: 'NotFound' })
      })
  },
  validateCode({ commit }, postData) {
    return new Promise((resolve, reject) => {
      api
        .post('question/code', postData)
        .then((response) => {
          commit('setQuestion', response.data)
          resolve(response.data)
        })
        .catch(() => {
          reject()
        })
    })
  },
  validateLocation({ getters, dispatch }, postData) {
    return new Promise((resolve, reject) => {
      const spot = getters.spot
      const distance = getDistance(
        spot.latitude,
        spot.longitude,
        postData.latitude,
        postData.longitude
      )
      if (distance < tolerance) {
        dispatch('getQestion', postData.id).then((response) => {
          resolve(response.data)
        })
      }
      reject()
    })
  },
  getQestion({ commit }, spot_id) {
    return new Promise((resolve, reject) => {
      api
        .post('question/spot', {spot: spot_id})
        .then((response) => {
          commit('setQuestion', response.data)
          resolve(response.data)
        })
        .catch(() => {
          reject()
        })
    })
  },
  /**
   * クイズ挑戦
   */
  challenge({ commit }, postData) {
    return new Promise((resolve, reject) => {
      api
        .post('question/correct', postData)
        .then(() => {
          resolve()
        })
        .catch(() => {
          reject()
        })
        .finally(() => {
          commit('clearQuestion')
        })
    })
  },
  /**
   * クイズキャンセル
   */
  cancelQuestion({ commit }) {
    commit('clearQuestion')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
