<template>
  <ul v-if="diff" class="countdown">
    <li v-if="days > 0">
      <p class="digit">{{ days }}</p>
    </li>
    <li>
      <p class="digit">{{ hours }}</p>
    </li>
    <li>
      <p class="digit">{{ minutes }}</p>
    </li>
    <li>
      <p class="digit">{{ seconds }}</p>
    </li>
  </ul>
  <div v-else>
    <p v-if="showMessage" class="message">{{ message }}</p>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'CountDown',
  components: {},

  props: {
    deadline: {
      type: Number,
      required: true,
    },
    stop: {
      type: Boolean,
    },
    message: {
      type: String,
      default: 'Time Out',
    },
  },
  emits: ['finished'],

  data() {
    return {
      now: null,
      date: null,
      diff: 0,
      interval: null,
      showMessage: false,
    }
  },
  computed: {
    seconds() {
      return this.twoDigits(Math.trunc(this.diff) % 60)
    },

    minutes() {
      return this.twoDigits(Math.trunc(this.diff / 60) % 60)
    },

    hours() {
      return this.twoDigits(Math.trunc(this.diff / 60 / 60) % 24)
    },

    days() {
      return this.twoDigits(Math.trunc(this.diff / 60 / 60 / 24))
    },
  },

  watch: {
    now(newValue) {
      this.diff = this.date.valueOf() - newValue
      if (this.diff <= 0 || this.stop) {
        // 終了時の処理
        this.showMessage = true
        if (typeof this.$emit('finished') === 'function') {
          this.$emit('finished')
        }
        this.diff = 0
        clearInterval(this.interval)
      }
    },
  },
  created() {
    this.date = dayjs(this.deadline)
    if (!this.date.isValid()) {
      throw new Error('Not in date format.')
    }

    this.interval = setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000)
    }, 1000)
  },
  unmounted() {
    clearInterval(this.interval)
  },

  methods: {
    twoDigits(value) {
      if (value.toString().length <= 1) {
        return '0' + value.toString()
      }
      return value.toString()
    },
  },
}
</script>

<style scoped>
.countdown {
  padding: 0;
  margin: 0;
}
.countdown li {
  display: inline-block;
  margin: 0 5px;
  text-align: center;
  position: relative;
}
.countdown li p {
  margin: 0;
}
.countdown li:after {
  content: ':';
  position: absolute;
  top: 0;
  right: -7px;
  font-size: 16px;
}
.countdown li:first-of-type {
  margin-left: 0;
}
.countdown li:last-of-type {
  margin-right: 0;
}
.countdown li:last-of-type:after {
  content: '';
}
.countdown .digit {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0;
}
.countdown .text {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 10px;
}
.message {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0;
}
</style>
