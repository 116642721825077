<template>
  <div class="text-center">
    <CountDown
      v-if="!isFinished"
      :deadline="timeLimit"
      message="タイムオーバー"
      @finished="timeOver"
    />
    <div v-else>
      <span>{{ gameStatus.message }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CountDown from '@/components/CountDown.vue'

export default {
  name: 'RallyCountDown',
  components: {
    CountDown,
  },

  computed: {
    ...mapGetters({
      timeLimit: 'gameLog/timeLimit',
      isFinished: 'gameLog/isFinished',
      gameStatus: 'gameLog/gameStatus',
    }),
  },

  methods: {
    ...mapActions({
      finish: 'gameLog/finish',
    }),
    timeOver() {
      this.finish().then(() => {
        this.showAlert()
      })
    },
    showAlert() {
      this.$swal.fire({
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        backdrop: 'rgba(255,255,255,0.7)',
        background: 'transparent',
        imageUrl:
          'https://storage.googleapis.com/escaper-205423.appspot.com/images/timeover.png',
        showConfirmButton: false,
        timer: 3000,
      })
    },
  },
}
</script>
