import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  signInAnonymously as FirebaseSignInAnonymously,
  signOut as FirebaseSignOut,
  onAuthStateChanged,
} from 'firebase/auth'

// State（状態）
const state = () => ({
  user: {},
})

// Getters（算出プロパティ）
const getters = {
  isAuth(state) {
    return !!Object.keys(state.user).length
  },
  user(state) {
    return state.user
  },
  uid(state) {
    if ('uid' in state.user) {
      return state.user.uid
    } else {
      return null
    }
  },
}

// Mutations（状態変更）
const mutations = {
  set(state, payload) {
    state.user = payload.user
  },
  clear(state) {
    state.user = {}
  },
}

// Actions（処理）
const actions = {
  /**
   * 匿名認証
   * @return {Promise} <user>
   */
  signIn({ commit }) {
    const auth = getAuth()
    setPersistence(auth, browserSessionPersistence)
    return new Promise((resolve) => {
      FirebaseSignInAnonymously(auth).then((UserCredential) => {
        const user = {
          uid: UserCredential.user.uid,
        }
        commit('set', { user: user })
        resolve(user)
      })
    })
  },
  /**
   * 認証情報破棄
   * @return void
   */
  signOut({ commit }) {
    const auth = getAuth()
    FirebaseSignOut(auth).then(() => {
      commit('clear')
    })
  },
  /**
   * 認証情報再取得
   * @return {Promise} <user>
   */
  restore({ commit }) {
    const auth = getAuth()
    return new Promise((resolve, reject) => {
      onAuthStateChanged(auth, (authUser) => {
        if (authUser) {
          const user = {
            uid: authUser.uid,
          }
          commit('set', { user: user })
          resolve(user)
        } else {
          reject()
        }
      })
    })
  },
  /**
   * ダミーデータ
   * @return {Promise} <user>
   */
  dummyUser({ commit }) {
    return new Promise((resolve) => {
      const user = {
        uid: 123456,
      }
      commit('set', { user: user })
      resolve(user)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
