<template>
  <div>
    <q-btn
      v-if="!isEntry"
      color="warning"
      class="full-width text-grey-10 text-weight-bold q-my-md"
      label="エントリー"
      size="xl"
      unelevated
      @click="prompt = true"
    />
    <q-btn
      v-else
      color="grey-6"
      class="full-width q-my-md"
      :label="gameStatus.message"
      size="xl"
      :to="{ name: 'RallySpots' }"
      unelevated
    />

    <q-dialog v-model="prompt" persistent @hide="dialogClose">
      <q-card style="width: 300px; max-width: 90vw">
        <q-card-section>
          <div class="text-body2">ニックネームを入力してください。</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input v-model="nickname" color="warning" autofocus />
          <ul v-if="errors.length" class="text-red-9">
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="キャンセル" v-close-popup />
          <q-btn
            color="warning"
            label="参加する"
            text-color="dark"
            unelevated
            @click="entry"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { useQuasar } from 'quasar'

export default {
  name: 'Entry',
  components: {},

  data() {
    return {
      prompt: false,
      errors: [],
      nickname: null,
    }
  },
  computed: {
    ...mapGetters({
      isEntry: 'gameLog/isEntry',
      gameStatus: 'gameLog/gameStatus',
    }),
  },

  created() {
    this.q = useQuasar()
  },
  beforeUnmount() {
    this.q.loading.hide()
  },

  methods: {
    ...mapActions({
      gameEntry: 'gameLog/entry',
    }),
    entry() {
      this.errors = []

      if (!this.nickname) {
        this.errors.push('ニックネームを入力してください。')
      } else {
        if (this.nickname.match(/^[!"#$%&'()*+.,/:;<=>?@[\\\]^`{|}~]*$/)) {
          this.errors.push('使用できない文字が含まれています。')
        }
      }

      if (!this.errors.length) {
        this.q.loading.show()
        this.gameEntry(this.nickname).then(() => {
          this.prompt = false
          this.q.loading.hide()
          this.$router.push({ name: 'RallySpots' })
        })
      }
    },
    dialogClose() {
      this.errors = []
      this.nickname = null
    },
  },
}
</script>

<style>
input[type='text'] {
  font-size: 16px;
  transform: scale(0.8);
}
</style>
