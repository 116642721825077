import './styles/quasar.sass'
import lang from 'quasar/lang/ja.js'
import '@quasar/extras/material-icons/material-icons.css'
import Loading from 'quasar/src/plugins/Loading.js';

export default {
  config: {},
  plugins: {
    Loading,
  },
  lang: lang,
}
