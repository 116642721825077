<template>
  <q-page class="q-pa-md">
    <h1>ランキング画面</h1>
    <q-table
      bordered
      flat
      hide-bottom
      row-key="name"
      :columns="columns"
      :rows="ranking"
    />
  </q-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'RallyRanking',
  components: {},

  data() {
    return {
      columns: [
        {
          align: 'left',
          field: 'nickname',
          label: 'ニックネーム',
          name: 'nickname',
        },
        {
          align: 'right',
          field: 'time',
          label: 'タイム',
          name: 'time',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      ranking: 'rally/ranking',
    }),
  },

  created() {
    this.getRanking()
  },

  methods: {
    ...mapActions({
      getRanking: 'rally/getRanking',
    }),
  },
}
</script>
