<template>
  <svg v-if="icon_name == 'escaper'" xmlns="http://www.w3.org/2000/svg" viewBox="0, 0, 24, 24">
    <path
      d="M17.854,4.861 C18.924,4.861 19.791,3.991 19.791,2.922 C19.791,1.853 18.924,0.985 17.854,0.985 C16.785,0.985 15.914,1.853 15.914,2.922 C15.914,3.992 16.785,4.861 17.854,4.861"
    />
    <path
      d="M11.182,6.3 L8.169,9.78 C7.99,10.003 7.717,10.137 7.415,10.137 C6.873,10.137 6.44,9.701 6.44,9.165 C6.44,8.872 6.566,8.611 6.768,8.432 L10.019,4.689 C10.198,4.475 10.467,4.338 10.768,4.338 L15.476,4.335 C15.822,4.335 16.13,4.473 16.361,4.695 L19.361,7.695 L21.848,5.21 C22.021,5.064 22.246,4.976 22.492,4.976 C23.051,4.976 23.5,5.428 23.5,5.984 C23.5,6.227 23.417,6.446 23.274,6.619 L20.274,9.643 C19.366,10.551 18.634,9.781 18.634,9.781 L16.794,7.933 L13.92,11.254 L16.554,13.89 C16.554,13.89 17.107,14.407 16.791,15.432 L15.315,22.011 C15.198,22.584 14.689,23.015 14.087,23.015 C13.395,23.015 12.833,22.453 12.833,21.759 C12.833,21.651 12.845,21.545 12.875,21.444 L14.087,16.046 L11.097,13.134 L8.52,16.005 C8.52,16.005 8.103,16.521 6.997,16.482 L1.778,16.491 C1.194,16.5 0.665,16.102 0.532,15.51 C0.378,14.836 0.796,14.174 1.473,14.025 C1.579,13.998 1.683,13.989 1.787,13.992 L6.278,14.007 L12.917,6.309 L11.182,6.3 z"
    />
    <path
      d="M20.785,2.542 L22.274,2.788 C22.43,2.814 22.536,2.961 22.51,3.117 L21.87,6.99 C21.844,7.146 21.696,7.251 21.54,7.225 L20.051,6.979 C19.894,6.953 19.788,6.806 19.814,6.65 L20.454,2.777 C20.48,2.621 20.628,2.516 20.785,2.542 z"
    />
    <path
      d="M20.641,3.08 L22.236,3.343 L21.743,6.325 L20.148,6.061 z"
      fill="#FFFFFF"
    />
    <path
      d="M21.053,6.68 L21.053,6.68 C21.036,6.781 20.941,6.849 20.84,6.832 C20.739,6.815 20.671,6.72 20.688,6.619 C20.704,6.518 20.799,6.45 20.9,6.467 C21.001,6.484 21.069,6.579 21.053,6.68 C21.053,6.68 21.053,6.68 21.053,6.68 z"
      fill="#FFFFFF"
    />
  </svg>
</template>

<script>
export default {
  name: 'TheIcon',
  components: {},

  props: {
    icon_name: {
      type: String,
      required: true,
    },
  },
}
</script>
