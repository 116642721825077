<template>
  <q-page class="q-pa-md full-width column justify-center content-center">
    <div class="line-box">
      <h2 class="text-h5 text-center">{{ rally.title }}</h2>
      <div class="q-my-md">{{ rally.description }}</div>
      <Entry />
    </div>
    <div class="q-my-sm text-center">ザ・脱出ラリー&reg;</div>
  </q-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Entry from '@/components/RallyEntry.vue'

export default {
  name: 'RallyHome',
  components: {
    Entry,
  },

  computed: {
    ...mapGetters({
      rally: 'rally/currentRally',
    }),
  },

  created() {
    this.fetchRally()
      .catch(() => {
        this.$router.push({ name: 'NotFound' })
      })
  },

  methods: {
    ...mapActions({
      fetchRally: 'rally/fetchRally',
    }),
  },
}
</script>

<style scoped>
.line-box {
  position: relative;
  padding: 0.25em 1em;
  border: solid 2px #f2c037;
  border-radius: 3px 0 3px 0;
  background-color: rgba(255, 255, 255, 0.6);
  max-width: 400px;
  width: 100%;
}
.line-box:before,
.line-box:after {
  content: '';
  position: absolute;
  width: 11px;
  height: 11px;
  border: solid 2px #f2c037;
  border-radius: 50%;
}
.line-box:after {
  top: -10px;
  left: -10px;
}
.line-box:before {
  bottom: -10px;
  right: -10px;
}
</style>
