<template>
  <q-page-sticky
    v-if="isEntry"
    :offset="[18, 18]"
    position="bottom-right"
    style="z-index: 1000"
  >
    <q-fab
      color="amber"
      text-color="black"
      icon="keyboard_arrow_up"
      direction="up"
      size="30px"
    >
      <q-fab-action
        color="amber"
        external-label
        icon="home"
        label="ホーム画面"
        label-position="left"
        text-color="black"
        :to="{ name: 'RallyHome' }"
      />
      <q-fab-action
        color="amber"
        external-label
        icon="apps"
        label="シート表示"
        label-position="left"
        text-color="black"
        :to="{ name: 'RallySpots' }"
      />
      <q-fab-action
        v-if="showMap"
        color="amber"
        external-label
        icon="map"
        label="全体マップ"
        label-position="left"
        text-color="black"
        :to="{ name: 'RallyMap' }"
      />
      <q-fab-action
        v-if="showRanking"
        color="amber"
        external-label
        icon="military_tech"
        label="ランキング"
        label-position="left"
        text-color="black"
        :to="{ name: 'RallyRanking' }"
      />
      <q-fab-action
        color="red"
        external-label
        icon="restart_alt"
        label="リセット"
        label-position="left"
        text-color="black"
        @click="retry"
      />
    </q-fab>
  </q-page-sticky>
</template>

<script>
import { ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { useQuasar } from 'quasar'

export default {
  name: 'RallyFooter',
  components: {},

  setup() {
    return {
      tab: ref(''),
    }
  },

  computed: {
    ...mapGetters({
      showMap: 'rally/showMap',
      showRanking: 'rally/showRanking',
      isEntry: 'gameLog/isEntry',
    }),
  },

  created() {
    this.q = useQuasar()
  },
  beforeUnmount() {
    this.q.loading.hide()
  },

  methods: {
    ...mapActions({
      gameRetry: 'gameLog/retry',
    }),
    retry() {
      this.$swal
        .fire({
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          cancelButtonText: 'キャンセル',
          confirmButtonColor: '#d33',
          confirmButtonText: '削除する',
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: true,
          text: '参加履歴を削除しますか？',
          title: 'ゲームリセット',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.q.loading.show()
            this.gameRetry().then(() => {
              setTimeout(() => {
                this.q.loading.hide()
                this.$router.push({ name: 'RallyHome' })
              }, 1000)
            })
          }
        })
    },
  },
}
</script>
