<template>
  <q-page class="bg-grey-3">
    <div class="bg-grey-7 text-center text-weight-bold text-white q-pa-md">
      {{ rally.title }}
    </div>
    <div class="bg-grey-7 q-pt-sm">
      <q-img fit="contain" src="/images/town.png" />
    </div>
    <div class="q-pa-md">
      <div class="text-right text-weight-bold q-mb-sm">
        {{ clearSpots.length }}/{{ rally.spots.length }}
      </div>
      <div class="row q-col-gutter-sm">
        <div class="col-4" v-for="spot in rally.spots" :key="spot.id">
          <SpotButton :spot_id="spot.id" :is_cleared="isCleared(spot.id)" />
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SpotButton from '@/components/SpotButton.vue'

export default {
  name: 'RallySpots',
  components: {
    SpotButton,
  },

  computed: {
    ...mapGetters({
      rally: 'rally/currentRally',
      clearSpots: 'gameLog/clearSpots',
    }),
  },

  created() {
    this.fetchRally()
  },

  methods: {
    ...mapActions({
      fetchRally: 'rally/fetchRally',
    }),
    isCleared(spot_id) {
      return this.clearSpots.includes(spot_id)
    },
  },
}
</script>
