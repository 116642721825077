<template>
  <div class="code-reader-wrap">
    <video
      autoplay
      class="code-reader-camera"
      ref="video"
      muted
      playsinline
    ></video>
  </div>
</template>

<script>
import jsQR from 'jsqr'

export default {
  name: 'CodeReader',
  components: {},

  emits: ['init', 'decode'],

  data() {
    return {
      interval: 200,
      intervalId: null,
      canvas: null,
      video: null,
    }
  },

  created() {
    this.canvas = document.createElement('canvas')
  },

  mounted() {
    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          facingMode: 'environment',
        },
      })
      .then((stream) => {
        this.$refs.video.srcObject = stream
        this.$refs.video.onloadedmetadata = () => {
          this.$refs.video.play()
          this.canvas.width = this.$refs.video.videoWidth
          this.canvas.height = this.$refs.video.videoHeight
        }
        this.intervalId = setInterval(() => {
          this.scanCode()
        }, this.interval)
      })
      .catch((error) => {
        console.log(error)
        this.$emit('init', error)
      })
  },
  beforeUnmount() {
    clearInterval(this.intervalId)
    this.canvas.remove()
    if (this.$refs.video && this.$refs.video.srcObject) {
      if ('getVideoTracks' in this.$refs.video.srcObject) {
        this.$refs.video.srcObject.getVideoTracks()[0].stop()
      }
    }
  },

  methods: {
    scanCode() {
      if (!this.$refs.video) {
        return
      }

      const ctx = this.canvas.getContext('2d')
      if (ctx == null) {
        return
      }
      ctx.drawImage(
        this.$refs.video,
        0,
        0,
        this.canvas.width,
        this.canvas.height
      )
      const imageData = ctx.getImageData(
        0,
        0,
        this.canvas.width,
        this.canvas.height
      )
      const code = jsQR(imageData.data, this.canvas.width, this.canvas.height)
      if (code) {
        this.$emit('decode', code.data)
      }
    },
  },
}
</script>

<style scoped>
.code-reader-wrap {
  height: calc(100vw * 0.66);
  position: relative;
  width: 100%;
  z-index: 0;
}

.code-reader-camera {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
</style>
