import { createStore } from 'vuex'
import rally from './modules/rally'
import account from './modules/account'
import gameLog from './modules/game-log'

const store = createStore({
  modules: {
    rally,
    account,
    gameLog,
  },
})

export default store
