<template>
  <div class="text-center">
    <q-btn
      :to="{
        name: 'RallySpot',
        params: { id: spot_id },
      }"
      class="full-width"
      outline
      padding="md lg"
      :color="(is_cleared) ? 'yellow-9' : 'grey-9'"
    >
      <q-icon size="3rem">
        <span v-if="is_cleared" class="material-icons">verified</span>
        <Icon v-else icon_name="escaper" />
      </q-icon>
    </q-btn>
  </div>
</template>

<script>
import Icon from '@/components/TheIcon.vue'

export default {
  name: 'SpotButton',
  components: {
    Icon,
  },

  props: {
    spot_id: {
      type: Number,
      required: true,
    },
    is_cleared: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
