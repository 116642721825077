<template>
  <q-btn
    color="black"
    fab
    icon="qr_code"
    @click="this.$refs.dialog.show()"
  />
  <q-dialog
    ref="dialog"
    maximized
    persistent
    transition-show="slide-up"
    transition-hide="slide-down"
    @hide="dialogClose"
  >
    <q-card class="bg-white">
      <q-bar>
        <q-icon name="qr_code" />
        <div>QRコードリーダー</div>
        <q-space />
        <q-btn dense flat icon="close" v-close-popup>
          <q-tooltip class="bg-white text-primary">Close</q-tooltip>
        </q-btn>
      </q-bar>

      <q-card-section>
        <CodeReader @init="onInit" @decode="onDecode" />
        <div class="q-mt-md">スポットにあるQRコードを読み取ってください。<br />
        <small>QRコードは株式会社デンソーウェーブの登録商標です。</small></div>
        <q-banner v-if="error" class="text-white bg-red q-mt-md" dense>
          <template v-slot:avatar>
            <q-icon name="warning" />
          </template>
          <div class="text-weight-bold">ERROR:</div>
          {{ error }}
        </q-banner>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { useQuasar } from 'quasar'
import CodeReader from '@/components/TheCodeReader.vue'

export default {
  name: 'SpotCode',
  components: {
    CodeReader,
  },

  props: {
    spot: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      error: '',
    }
  },

  created() {
    this.q = useQuasar()
  },
  beforeUnmount() {
    this.q.loading.hide()
  },

  methods: {
    ...mapActions({
      validateCode: 'rally/validateCode',
    }),
    onDecode(result) {
      this.dataValidation(result)
    },
    onInit(error) {
      switch (error.name) {
        case 'NotAllowedError':
          this.error = 'カメラへのアクセスを許可してください'
          break
        case 'NotFoundError':
          this.error = 'このデバイスにはカメラがありません'
          break
        case 'NotSupportedError':
          this.error = '安全な接続環境が必要です (HTTPS, localhost)'
          break
        case 'NotReadableError':
          this.error = 'カメラはすでに使用されています'
          break
        case 'OverconstrainedError':
          this.error = '接続されたカメラは適切ではありません'
          break
        case 'StreamApiNotSupportedError':
          this.error = 'このブラウザはサポートされていません'
          break
      }
    },
    dialogClose() {
      this.error = ''
    },
    dataValidation(uuid) {
      // 読み取りデータ送信
      const data = {
        id: this.spot.id,
        uuid: uuid,
      }
      this.q.loading.show()
      this.validateCode(data)
        .catch(() => {
          this.showAlert()
        })
        .finally(() => {
          this.$refs.dialog.hide()
          this.q.loading.hide()
        })
    },
    showAlert() {
      this.$swal.fire({
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        backdrop: 'rgba(255,255,255,0.9)',
        background: 'transparent',
        icon: 'warning',
        showConfirmButton: false,
        text: 'QRコードの値が正しくありません。',
        timer: 2000,
      })
    },
  },
}
</script>
