<template>
  <q-page class="bg-grey-3">
    <div class="row justify-between q-pa-md">
      <div class="text-weight-bold">
        <q-icon name="pin_drop" />{{ spot.name }}
      </div>
      <div v-if="isCleared(spot.id)">
        <q-badge color="yellow-9">クリア済み</q-badge>
      </div>
    </div>
    <div>
      <q-tabs
        v-model="tab"
        class="text-grey bg-white"
        dense
        inline-label
        narrow-indicator
      >
        <q-tab label="情報" name="info" />
        <q-tab
          v-if="showMap && spot.latitude && spot.longitude"
          label="マップ"
          name="map"
        />
        <q-tab
          v-if="isCleared(spot.id) && spot.special_content"
          class="text-yellow-9"
          icon="verified"
          name="detail"
        />
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab">
        <q-tab-panel name="info">
          <q-img
            v-if="spot.image"
            class="q-mb-md"
            fit="cover"
            :src="spot.image"
          />
          <div class="text-body1">{{ spot.content }}</div>
        </q-tab-panel>

        <q-tab-panel
          name="map"
          v-if="showMap && spot.latitude && spot.longitude"
        >
          <SpotMap :latitude="spot.latitude" :longitude="spot.longitude" />
        </q-tab-panel>

        <q-tab-panel name="detail">
          <div class="text-body2">{{ spot.special_content }}</div>
        </q-tab-panel>
      </q-tab-panels>
    </div>
  </q-page>
  <q-page-sticky
    v-if="!isCleared(spot.id) && !isFinished"
    position="bottom-left"
    :offset="[18, 18]"
  >
    <SpotLocation v-if="spot.is_location" class="q-mr-sm" :spot_id="id" />
    <SpotCode v-if="spot.is_code" :spot="spot" />
    <SpotQuestion />
  </q-page-sticky>
</template>

<script>
import { ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import SpotLocation from '@/components/SpotLocation.vue'
import SpotCode from '@/components/SpotCode.vue'
import SpotQuestion from '@/components/SpotQuestion.vue'
import SpotMap from '@/components/SpotMap.vue'

export default {
  name: 'RallySpot',
  components: {
    SpotLocation,
    SpotCode,
    SpotQuestion,
    SpotMap,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      spot: 'rally/spot',
      showMap: 'rally/showMap',
      clearSpots: 'gameLog/clearSpots',
      isFinished: 'gameLog/isFinished',
    }),
  },

  setup() {
    return {
      tab: ref('info'),
    }
  },

  created() {
    this.getSpot(this.id)
  },

  methods: {
    ...mapActions({
      getSpot: 'rally/getSpot',
    }),
    isCleared(spot_id) {
      return this.clearSpots.includes(spot_id)
    },
  },
}
</script>
