<template>
  <q-page class="bg-grey-3">
    <div class="bg-grey-7 text-center text-weight-bold text-white q-pa-md">
      {{ rally.title }}
    </div>
    <div>
      <div id="mymap"></div>
    </div>
  </q-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import L from 'leaflet'

export default {
  name: 'RallyMap',
  components: {},

  data() {
    return {
      map: null,
    }
  },
  computed: {
    ...mapGetters({
      rally: 'rally/currentRally',
      spots: 'rally/spots',
    }),
  },

  watch: {
    spots() {
      let markers = L.featureGroup()
      this.spots.forEach((spot) => {
        if (spot.latitude && spot.longitude) {
          markers.addLayer(L.marker([spot.latitude, spot.longitude]))
        }
      })
      markers.addTo(this.map)
      this.map.fitBounds(markers.getBounds())
    },
  },

  created() {
    this.getSpots()
  },
  mounted() {
    this.map = L.map('mymap', {
      dragging: false,
      scrollWheelZoom: false,
      zoom: 15,
    })
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map)
  },

  methods: {
    ...mapActions({
      getSpots: 'rally/getSpots',
    }),
  },
}
</script>

<style scoped>
#mymap {
  width: 100%;
  height: calc(100vh - 32px - 53px);
  margin-left: auto;
  margin-right: auto;
}
</style>
