<template>
  <q-layout view="hHh lpR fFf">
    <q-header class="bg-dark text-white">
      <q-bar v-if="nickName" @click="toSheet">
        <div class="text-weight-bold">{{ nickName }}</div>
        <q-space />
        <div class="text-weight-bold">
          <RallyCountDown />
        </div>
      </q-bar>
    </q-header>
    <q-page-container id="container">
      <router-view></router-view>
    </q-page-container>
    <Footer />
  </q-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Footer from '@/components/RallyFooter.vue'
import RallyCountDown from '@/components/RallyCountDown.vue'

export default {
  name: 'Rally',
  components: {
    Footer,
    RallyCountDown,
  },

  computed: {
    ...mapGetters({
      nickName: 'gameLog/nickName',
    }),
  },

  methods: {
    toSheet() {
      this.$router.push({ name: 'RallySpots' })
    },
  },
}
</script>

<style scoped>
#container {
  background-image: url('/images/background.jpg');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
</style>
