<template>
  <div id="mymap"></div>
</template>

<script>
import L from 'leaflet'

export default {
  name: 'SpotMap',
  components: {},

  props: {
    latitude: {
      type: Number,
      required: true,
    },
    longitude: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      map: null,
    }
  },

  mounted() {
    this.map = L.map('mymap', {
      center: L.latLng(this.latitude, this.longitude),
      dragging: false,
      scrollWheelZoom: false,
      zoom: 15,
    })
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map)

    this.map.addLayer(L.marker([this.latitude, this.longitude]))
  },
}
</script>

<style scoped>
#mymap {
  width: 100%;
  height: calc(100vw * 0.66);
  margin-left: auto;
  margin-right: auto;
}
</style>
