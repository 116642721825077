<template>
  <transition name="fade">
    <div
      v-if="show"
      :class="['the-splash', { 'the-splash--fixed': fixed }]"
      :style="splashStyle"
    >
      <div>
        <div class="the-splash__anim">
          <img :src="logo" :style="imageStyle" />
        </div>
        <div v-if="title" class="the-splash__text">
          <span>{{ title }}</span>
        </div>
        <div
          v-if="customText"
          class="the-splash__custom-text"
          v-html="customText"
        ></div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'TheSplash',
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    logo: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    customText: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#f2c037',
    },
    size: {
      type: [Number, String],
      default: 180,
    },
    fixed: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
      default: '',
    },
  },
  created() {
    this.setColor()
  },
  computed: {
    imageStyle() {
      let width = this.size + 'px'

      return {
        width,
      }
    },

    splashStyle() {
      let backgroundColor = ''

      if (this.fixed && this.backgroundColor) {
        backgroundColor = this.backgroundColor
      } else if (this.fixed) {
        backgroundColor = '#ffffff'
      } else {
        backgroundColor = 'transparent'
      }

      return {
        backgroundColor,
      }
    },
  },
  methods: {
    setColor() {
      document.documentElement.style.setProperty('--splash-color', this.color)
    },
  },
}
</script>

<style lang="scss" scoped>
$splash-color: var(--splash-color);

.the-splash {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  &--fixed {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: white;
  }
}

.the-splash__anim {
  text-align: center;
  animation: splashAnimation 1.3s infinite;

  img {
    margin: auto;
  }
}

.the-splash__text {
  margin-top: 50px;
  font-size: 1rem;
  text-align: center;
}

.the-splash__custom-text {
  margin-top: 50px;
  text-align: center;
}

@keyframes splashAnimation {
  0% {
    filter: drop-shadow(0px 0px 0px $splash-color);
  }
  100% {
    filter: drop-shadow(0px 0px 200px $splash-color);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
