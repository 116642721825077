<template>
  <router-view></router-view>
  <splash :show="isLoading" :logo="logo" :size="300" :fixed="true" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Splash from '@/components/TheSplash.vue'

export default {
  name: 'App',
  components: {
    Splash,
  },

  data() {
    return {
      isLoading: true,
      logo: '/images/logo01.png',
    }
  },

  computed: {
    ...mapGetters({
      isEntry: 'gameLog/isEntry',
    }),
  },

  beforeMount() {
    if (!this.isEntry) {
      this.gameRestore().then(this.isLoaded())
    } else {
      this.isLoaded()
    }
  },

  methods: {
    ...mapActions({
      gameRestore: 'gameLog/restore',
    }),
    isLoaded() {
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
  },
}
</script>
