<template>
  <q-layout>
    <q-page-container>
      <q-page class="q-pa-md">
        <h1>Not Found</h1>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
export default {
  name: 'Rally',
  components: {},
}
</script>
